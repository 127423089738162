const CitiesAndProvincesSouthAfrica = [
    { value: null, label: '--Select--' },
    { value: 'Alice', label: 'Alice' },
    { value: 'Gaborone', label: 'Gaborone' },
    { value: 'Gabane', label: 'Gabane' },
    { value: 'Francistown', label: 'Francistown' },
    { value: 'Jwaneng', label: 'Jwaneng' },
    { value: 'Palapye', label: 'Palapye' },
    { value: 'Butterworth', label: 'Butterworth' },
    { value: 'East London', label: 'East London' },
    { value: 'Graaff-Reinet', label: 'Graaff-Reinet' },
    { value: 'Grahamstown', label: 'Grahamstown' },
    { value: 'King William’s Town', label: 'King William’s Town' },
    { value: 'Mthatha', label: 'Mthatha' },
    { value: 'Port Elizabeth', label: 'Port Elizabeth' },
    { value: 'Queenstown', label: 'Queenstown' },
    { value: 'Uitenhage', label: 'Uitenhage' },
    { value: 'Zwelitsha', label: 'Zwelitsha' },
    { value: 'Bethlehem', label: 'Bethlehem' },
    { value: 'Bloemfontein', label: 'Bloemfontein' },
    { value: 'Jagersfontein', label: 'Jagersfontein' },
    { value: 'Kroonstad', label: 'Kroonstad' },
    { value: 'Odendaalsrus', label: 'Odendaalsrus' },
    { value: 'Parys', label: 'Parys' },
    { value: 'Phuthaditjhaba', label: 'Phuthaditjhaba' },
    { value: 'Sasolburg', label: 'Sasolburg' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Welkom', label: 'Welkom' },
    { value: 'Benoni', label: 'Benoni' },
    { value: 'Boksburg', label: 'Boksburg' },
    { value: 'Brakpan', label: 'Brakpan' },
    { value: 'Carletonville', label: 'Carletonville' },
    { value: 'Germiston', label: 'Germiston' },
    { value: 'Johannesburg', label: 'Johannesburg' },
    { value: 'Krugersdorp', label: 'Krugersdorp' },
    { value: 'Pretoria', label: 'Pretoria' },
    { value: 'Randburg', label: 'Randburg' },
    { value: 'Randfontein', label: 'Randfontein' },
    { value: 'Roodepoort', label: 'Roodepoort' },
    { value: 'Soweto', label: 'Soweto' },
    { value: 'Springs', label: 'Springs' },
    { value: 'Vanderbijlpark', label: 'Vanderbijlpark' },
    { value: 'Vereeniging', label: 'Vereeniging' },
    { value: 'Durban', label: 'Durban' },
    { value: 'Empangeni', label: 'Empangeni' },
    { value: 'Ladysmith', label: 'Ladysmith' },
    { value: 'Newcastle', label: 'Newcastle' },
    { value: 'Pietermaritzburg', label: 'Pietermaritzburg' },
    { value: 'Pinetown', label: 'Pinetown' },
    { value: 'Ulundi', label: 'Ulundi' },
    { value: 'Umlazi', label: 'Umlazi' },
    { value: 'Giyani', label: 'Giyani' },
    { value: 'Lebowakgomo', label: 'Lebowakgomo' },
    { value: 'Musina', label: 'Musina' },
    { value: 'Phalaborwa', label: 'Phalaborwa' },
    { value: 'Polokwane', label: 'Polokwane' },
    { value: 'Seshego', label: 'Seshego' },
    { value: 'Sibasa', label: 'Sibasa' },
    { value: 'Thabazimbi', label: 'Thabazimbi' },
    { value: 'Emalahleni', label: 'Emalahleni' },
    { value: 'Nelspruit', label: 'Nelspruit' },
    { value: 'Secunda', label: 'Secunda' },
    { value: 'Klerksdorp', label: 'Klerksdorp' },
    { value: 'Mahikeng', label: 'Mahikeng' },
    { value: 'Mmabatho', label: 'Mmabatho' },
    { value: 'Potchefstroom', label: 'Potchefstroom' },
    { value: 'Rustenburg', label: 'Rustenburg' },
    { value: 'Kimberley', label: 'Kimberley' },
    { value: 'Kuruman', label: 'Kuruman' },
    { value: 'Port Nolloth', label: 'Port Nolloth' },
    { value: 'Bellville', label: 'Bellville' },
    { value: 'Cape Town', label: 'Cape Town' },
    { value: 'Constantia', label: 'Constantia' },
    { value: 'George', label: 'George' },
    { value: 'Hopefield', label: 'Hopefield' },
    { value: 'Oudtshoorn', label: 'Oudtshoorn' },
    { value: 'Paarl', label: 'Paarl' },
    { value: 'Simon’s Town', label: 'Simon’s Town' },
    { value: 'Stellenbosch', label: 'Stellenbosch' },
    { value: 'Swellendam', label: 'Swellendam' },
    { value: 'Worcester', label: 'Worcester' }
    // Add more cities and provinces as needed
  ];

export default CitiesAndProvincesSouthAfrica;
