import React, {useState,useEffect} from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2';
import BASE_URL from './config';



function Login(){
  const history = useHistory();
const MySwal = withReactContent(Swal)
const [state , setState] = useState({
  loginemail: '',
  loginpassword: ''
})
const [passtype, setpasstype] = useState('password');
const hideme = (e) => {
  e.preventDefault(); 
  setpasstype('text')
}

const adminlogin = (e) => {
  history.push('/')
}

const loginUrl = BASE_URL+'/signin';
const handleChange = (e) => {
  const {id , value} = e.target   
  setState(prevState => ({
      ...prevState,
      [id] : value
  }))
}
var postloginData = {
  "email": state.loginemail,
  "password": state.loginpassword,
};

let axiosConfig = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
    }
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault()
    if (!state.loginemail || !state.loginemail){
      MySwal.fire({
        title: <p>Please fill all fields</p>,
        didOpen: () => {
        },
      }).then(() => {
      })
    }else{
      MySwal.fire({
        title: <p>please wait...</p>,
        didOpen: () => {
          MySwal.showLoading()
          axios.post(loginUrl, postloginData, axiosConfig)
        .then((response) => {
          console.log("response received: ", response);
          Swal.close()
            localStorage.setItem("ladpsouthafricaaccessorgtoken", response.data.data.accessToken);
            localStorage.setItem("ladpsouthafricaorgfirstname", response.data.data.firstname);
            localStorage.setItem("ladpsouthafricauerorgid", response.data.data.client_id);
            localStorage.setItem("ladpsouthafricauserid", response.data.data.id);
            localStorage.setItem("ladpsouthafricaorgemail", response.data.data.email);
                window.location.href="orghome";
            
          })
          .catch((err) => {
            Swal.close()
            console.log("AXIOS ERROR: ", err);
            return MySwal.fire(err.response.data.message);
            window.location.reload(false)
          })
        },
      }).then(() => {
      })
    }
  }
    

      return (
     <div>
       <div className="d-flex flex-column flex-root" style={{backgroundImage: 'url(assets/media/black.png)', backgroundSize: 'contain'}}>
          <div className="d-flex flex-column flex-column-fluid flex-lg-row">
            <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">              
              <div className="d-flex flex-center flex-lg-start flex-column">              
                <center>
                <img alt="Logo" src="assets/media/slp3.png" style={{width:'70%'}} />
                  <br/>
                <h3 className="text-white fw-normal m-0"> 
                Empowering Suppliers, Enhancing Efficiency!
                </h3>  
                </center> 
              </div>
            </div>
          <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
            <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
              <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
                <form className="form w-100" noValidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="/metronic8/demo16/../demo16/index.html" action="#">
                  <div className="text-center mb-11">
                    <h1 className="text-gray-900 fw-bolder mb-3">
                      Sign In
                    </h1>
                   
                  </div>
                  
                  <div className="separator separator-content my-14">
                    <span className="w-125px text-gray-500 fw-semibold fs-7">Email & Password</span>
                  </div>
                  <div className="fv-row mb-8">
                    <input type="text" placeholder="Email" value={state.loginemail} onChange={handleChange} id="loginemail" name="loginemail" autoComplete="off" className="form-control bg-transparent" /> 
                  </div>
                  <div className="fv-row mb-3">    
                    <input type="password" placeholder="Password" value={state.loginpassword} onChange={handleChange} id="loginpassword" name="loginpassword" autoComplete="off" className="form-control bg-transparent" />
                  </div>
                  <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />
                    <a href='#' onClick={adminlogin} className="link-info text-danger" style={{marginLeft: '-180px'}}>
                     Back to Launch
                    </a>
                    <a href="/forgotpassword" className="link-info">
                      Forgot Password ?
                    </a>
                  </div>
                  <div className="d-grid mb-10">
                    <button onClick={handleLoginSubmit}  className="btn btn-info">
                      <span className="indicator-label">
                        Sign In</span>
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                  </div>
                  <br/>
                  <br/>
                  <br/>
                </form>
                
              </div>
              
            </div>
           
          </div>
          <br/>
                 
        </div>
        <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                 
      </div>
   </div>
               
        );
 
  };

export default Login;
