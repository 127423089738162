// Header.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';


function Footer() {

  const location = useLocation();

  const isCurrentPage = (path) => {
    // Check if the current route matches the provided path
    return location.pathname === path;
  };


  return (
    <div>
   <div className="footer-wrapper col-xl-12">
        <div className="footer-section f-section-1">
         <center style={{fontSize:'10px', color: 'blue'}}> <p className>© 2024 <a style={{color: 'blue'}} target="_blank" href="https://starlightsoftware.co.bw/">Copyright Starlight Software Solutions (PTY) Ltd.</a></p></center>
        </div>
        <div className="footer-section f-section-2">
        </div>
      </div>
  </div>
  );
}

export default Footer;
