import React, {useState,useEffect} from 'react';
import Header from './header'; // Import your Header component
import Footer from './footer'; // Import your Footer component
import axios from "axios";
import dayjs from "dayjs";
import Spinner from 'react-bootstrap/Spinner';
import CitiesAndProvincesSouthAfrica from './cities';
import Select from 'react-select';
import Swal from 'sweetalert2';
import Countries from './countries';
import withReactContent from 'sweetalert2-react-content'
import DataTable from "react-data-table-component";
import BusinessCategories from './BusinessCategories';
import BBEEE from './bbeee';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import BASE_URL from './config';



function Filter(){
    const MySwal = withReactContent(Swal);
    const [org_info, setorg] = useState([]);
    const orgURL = BASE_URL+'/fetchSupplier'
    const filterUrl = BASE_URL+'/createfilter'
    const token = localStorage.getItem("ladpsouthafricaaccesstoken")
    const [state , setState] = useState({
      bbee: '',
      country: '',
      businesscategory: '',
      esg: '',
      blackowned: '',
      blackwomanowned: '',
      city: '',
      designatedgroup: ''

  })
        
    const handleChange2 = (fieldName) => (selectedOption) => {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: selectedOption.value
      }));
    };


    const headers2 = { 
      headers: {
        'AccessToken': token,
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': "*",
      }
    };

          const body = {
            'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),

          }

          const ESG = [
            { value: null, label: '--Select--' },
            { value: "YES", label: "YES" },
            { value: "NO", label: "NO" },
          ];
          

          const getorginfo = async (e) => {
            axios.post(orgURL, body, headers2)
            .then((response) => {
              console.log("response received: ", response);
              if(!response){
                setorg(0);
              }else{
                const sortedArray = response.data.data;
                const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
                setorg(numDescending);
                setorg(numDescending);
              }
            });
          };


          const createFilter = async (e) => {
            e.preventDefault();
            if (!state.country && !state.businesscategory) {
              MySwal.fire({
                text: 'Please enter at least one field',
                confirmButtonColor: '#3085d6',
                didOpen: () => {
                },
              }).then(() => {
              });
            }else{
              MySwal.showLoading()
               const postData = {
              'accesstoken': localStorage.getItem("ladpsouthafricaaccesstoken"),
               'supplierlocation': state.country,
               'supplierbbeee': state.bbee,
               'supplieresg': state.esg,
               'business_cat': state.businesscategory,
               'contact_city': state.city,
               'blackowned': state.blackowned,
               'designatedgroup': state.designatedgroup,
               'blackwomanowned': state.blackwomanowned
               }
               console.log(postData);
               MySwal.fire({
                   title: <p>please wait...</p>,
                   didOpen: () => {
                     MySwal.showLoading()
                     axios.post(filterUrl, postData, headers2)
                   .then((response) => {
                     console.log("response received: ", response);
                     Swal.close()
                     const sortedArray = response.data;
                     const numDescending = [...sortedArray].sort((a, b) => b.id - a.id);
                     setorg(numDescending);
                     setorg(numDescending);
                     })
                     .catch((err) => {
                     Swal.close()
                       console.log("AXIOS ERROR: ", err);
                       if(!err.response){
                       return MySwal.fire(err.message)
                       }else{
                       return MySwal.fire(err.response.data.message)
                       }
                     })
                   },
                 }).then(() => {
                 })
           }
          };

          useEffect(() => {
            getorginfo();
          }, []);

          const columns = [
          {
            name: 'COMPANY NAME',
            selector: (row) => row.suppliername,
            sortable: true,
            width: '12rem',
            cellExport: row => row.suppliername 

          },
          {
            name: 'CATEGORY',
            selector: (row) => row.business_cat,
            sortable: true,
            width: '13rem',
            cellExport: row => row.business_cat 

          },
          {
            name: 'LOCATION',
            selector: (row) => row.supplierlocation,
            sortable: true,
            width: '10rem',
            cellExport: row => row.supplierlocation 

          },
          {
            name: 'CITY',
            selector: (row) => row.contact_city,
            sortable: true,
            width: '15rem',
            cellExport: row => row.contact_city 

          },
          {
            name: 'ACTIONS',
            button: true,
            width: '10rem',
            cell: (row) => <button onClick={() => vieworganization(row.id)} className="btn btn-sm btn-info btn-active-light-primary">View</button>

          },
        ];
          
        const data=org_info;
        const tableData = {
          columns,
          data
        };

        const vieworganization = (selectedId) => {
          const selectedID = selectedId;
          if (selectedID) {
            const nextPageUrl = '/vieworganization'; 
            window.location.href = nextPageUrl;
            window.location.href = "viewsupplier?myid=" + selectedID;
          } else {
            console.error(`Data for ID ${selectedId} not found`);
          }
        }
      return (
     <div>
     <Header/>
     <div>
        <div className="toolbar py-3 py-lg-5" id="kt_toolbar">
          <div id="kt_toolbar_container" className=" container-xxl  d-flex flex-stack flex-wrap">
            <div className="page-title d-flex flex-column">
              <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bolder fs-3 m-0">
                Create Filter
                <span className="page-desc text-muted fs-7 fw-semibold pt-1">
                </span>
              </h1>
            </div>
            
            <br/>
            <br/>
            
          </div>
        </div>
        
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start  container-xxl ">
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                {/* <h3 className="card-title  flex-column">
                  <span className="card-label fw-bold fs-3 mb-1">All Organisations</span>
                </h3> */}
                {/* <div className='row'> */}
              {/* <div className='col-lg-2'>
                <label className="fw-semibold fs-6 mb-2">Supplier Location</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={Countries}
                        />
               </div> */}
               <div className='col-lg-2'>
                <label className="fw-semibold fs-6 mb-2">SUPPLIER CITY</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.country, label: state.country }}  onChange={handleChange2('country')}  
                           options={CitiesAndProvincesSouthAfrica}
                        />
               </div>
               <div className='col-lg-2'>
                <label className=" fw-semibold fs-6 mb-2">SERVICE CATEGORY</label>
                <Select  className="react-select-container"  classNamePrefix="react-select" value={{ value: state.businesscategory, label: state.businesscategory }}  onChange={handleChange2('businesscategory')}  
                           options={BusinessCategories}
                        />
               </div>
              
             
               <div className='col-lg-3'>
                <br/>
                <button onClick={createFilter} className='btn btn-lg btn-info'>Submit Filter</button>
               </div>
              {/* </div> */}
              </div>
             
              <div className="card-body py-3">
                <div className="table-responsive">
                  <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                  {org_info == null ? <Spinner animation="border" /> : ""}
                  <DataTableExtensions {...tableData}>
                                <DataTable className=""
                                  columns={columns}
                                  data={data}
                                  noHeader
                                  defaultSortField="id"
                                  defaultSortAsc={false}
                                  pagination
                                  exportHeaders= {true}
                                  highlightOnHover
                                />
                              </DataTableExtensions>
                  </table>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
       
       
        <br/>
        <br/>
        <br/>
     <Footer/>
              </div>
               
        );
 
  };

export default Filter;
